import React from 'react'
import { Divider, Radio, Input, Carousel } from 'antd'
import styled from 'styled-components'
import Button from '../../components/form/buttons'
import MediaQuery from 'react-responsive'
import '../styles/input.css'
import { input } from 'aws-amplify'


const mainDisplay = { backgroundColor: '#ECECEC', width: '100%' }
const mainConteneWrapper = { width: '75%', maxWidth: '1130px', marginLeft: 'auto', marginRight: 'auto', marginTop: '30px' }
const mainConteneWrapperMobile = { width: '100%', maxWidth: '1130px', marginLeft: 'auto', marginRight: 'auto', marginTop: '30px' }
const contentWraper = { width: '100%', borderRadius: '5px', marginBottom: '30px' }
const pictureWraper = { width: '100%', borderRadius: '5px', backgroundColor: '#EFE5E4', marginBottom: '-20px' }
const sectionTitle = { backgroundColor: '#862C23', color: 'white', marginTop: '40px' }
const sectionDetail = { backgroundColor: 'white', marginTop: '20px' }
const sectionDetailTitle = { color: '#862C23', paddingLeft: '5%', paddingTop: '15px', margin: '0' }
const divider = { width: '3px', height: '35px', marginTop: '-10px', marginLeft: '5%', marginRight: '15px' }
const dividerMobile = { width: '3px', height: '25px', marginTop: '-5px', marginLeft: '5%', marginRight: '15px' }
const contentDivider = { margin: '0', height: '3px', width: '90%', minWidth: '50%', display: 'inline-block' }
const radioGroup = { minWidth: '100%' }
const radioGroupMobile = { minWidth: '100%', textAlign: 'center' }
const radioItem = { display: 'block', marginBottom: '10px', marginTop: '5px' }
const radiobuttomItem = { width: '20%', height: `120px`, margin: '20px', textAlign: 'center' }
const radiobuttomItemMobile = { width: '40%', height: `120px`, margin: '10px', textAlign: 'center' }
const logoImage = { display: 'block', borderRadius: '50%', marginLeft: 'auto', marginRight: 'auto', width: '15%', maxWidth: '173px', minWidth: '120px' }
const inputItem = { color: '#862C23', paddingLeft: '5%', margin: '0' }
const buttonBuy = { textAlign: 'center' }
const Buttontemp = styled.button`
  cursor: pointer;
  background: transparent;
`
const datetimeformater = (rawDateTime, lang, type) => {
    let date, month, year = ''
    let dateTime = new Date(rawDateTime);
    if (type === 'date') {
        year = new Intl.DateTimeFormat(lang, { year: 'numeric' }).format(dateTime);
        month = new Intl.DateTimeFormat(lang, { month: 'long' }).format(dateTime);
        date = new Intl.DateTimeFormat(lang, { day: '2-digit' }).format(dateTime);
        let convertDate = `${month} ${date},${year}`
        return convertDate
    } else {
        let convertTime = dateTime.toLocaleTimeString().replace(':34', '')
        return convertTime
    }
}
export default function BuyTicketGoods(props) {
    return (
        <div style={mainDisplay}>
            <MediaQuery minWidth={769}>
                <div style={mainDisplay}>
                    <div style={mainConteneWrapper}>
                        {/* Header Section*/}
                        <div style={{ ...contentWraper, ...{ marginBottom: '0' } }}>
                            <img
                                src={props.title.logo_img} alt="Image"
                                style={logoImage}
                            />
                        </div>
                        <div style={{ ...contentWraper, ...{ backgroundColor: 'white', marginTop: '-7%' } }}>
                            <div style={{ paddingTop: '80px', paddingBottom: '20px', textAlign: 'center', height: '210px', marginBottom: '20px' }}>
                                <h2> {(props.lang === 'EN') ? props.titleOriginal.title_en : props.titleOriginal.title_th} </h2>
                                <h1 style={{ fontSize: '20px', color: '#555555' }}> {(props.lang === 'EN') ? props.titleOriginal.description_en : props.titleOriginal.description_th} </h1>
                                <div>
                                    <i style={{ fontSize: '23px', marginRight: '20px', fontWeight: '500' }} className="fas fa-calendar-alt">
                                        <span
                                            style={{
                                                fontSize: '20px', marginLeft: '10px',
                                                fontFamily: 'SF Compact Display Medium, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol',
                                            }}>
                                            {datetimeformater(props.titleOriginal.date, props.lang.toLowerCase(), 'date')}
                                        </span>
                                    </i>
                                    <i style={{ fontSize: '24px' }} className="far fa-clock">
                                        <span
                                            style={{
                                                fontSize: '20px', marginLeft: '10px',
                                                fontFamily: 'SF Compact Display Medium, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol',
                                            }}>
                                            {datetimeformater(props.titleOriginal.date, props.lang.toLowerCase(), 'time')}
                                        </span>
                                    </i>
                                </div>
                            </div>
                        </div>
                        {/* Header Section*/}
                        {/* Detail Section*/}
                        {/* Ticket */}
                        { props.ticket.length > 0 ? 
                        (
                        <div>
                            <div style={{ ...contentWraper, ...sectionTitle }} >
                                <div>
                                    <Divider style={divider} type='vertical' />
                                    <h2 style={{ display: 'inline' }}>{props.locale['TICKET_DETAIL']}</h2>
                                </div>
                            </div>
                            <div style={{ ...contentWraper, ...sectionDetail }}>
                                {props.ticket.map((data, index) => {
                                    return (
                                        <div key={`ticketTitle${index}`} >
                                            <h3 style={sectionDetailTitle}>{data[0].name_en.slice(8, (data[0].name_en.indexOf(' Subtitle')))}</h3>
                                            <div style={{ textAlign: 'center' }}>
                                                <Divider style={contentDivider} />
                                            </div>
                                            <Radio.Group style={radioGroup}>
                                                {props.ticket[index].map((d, i) => {
                                                    return (
                                                        <div key={`subTicketTitle${i}`}>
                                                            <div style={{ width: '40%', display: 'inline-block', paddingLeft: '5%' }}>
                                                                <Radio value={d.id} style={radioItem}
                                                                    onClick={() => {
                                                                        props.selectTicketGoodsDeliveryhandeler(
                                                                            'Ticket', d.name_en, d.id, 1,
                                                                            d.price, d.currency
                                                                        )
                                                                    }}
                                                                >
                                                                    {(d.name_en.slice(((d.name_en.indexOf(' Subtitle') + 10))))}
                                                                </Radio>
                                                            </div>
                                                            <div style={{ width: '60%', display: 'inline-block' }}>
                                                                {`${d.currency}  ${d.price}`}
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </Radio.Group>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        ) : null}
                        {/* Ticket */}
                        {/* Goods and souvenir */}
                        {((props.isOpen.GoodsAndSouvenir === true) || (props.ticket.length <= 0)) ? (
                            <div>
                                <div style={{ ...contentWraper, ...sectionTitle }} >
                                    <div>
                                        <Divider style={divider} type='vertical' />
                                        <h2 style={{ display: 'inline' }}>{props.locale['GOODS_AND_SOUVENIR']}</h2>
                                    </div>
                                </div>
                                {/* Goods Image Display */}
                                <div style={pictureWraper}>
                                    <Carousel style={{ textAlign: 'center', height: '300px' }}>
                                        {props.souvenirImage.map((data, index) => {
                                            return (
                                                <div key={`souvenirImage${index}`} >
                                                    {props.souvenirImage[index].map((d, i) => {
                                                        let imageIndex = Object.keys(d)
                                                        return (
                                                            <img
                                                                key={`image${i}`}
                                                                onClick={() => {
                                                                    let fileImageURL = { url: d[imageIndex] }
                                                                    props.onPreview(fileImageURL)
                                                                }}
                                                                src={d[imageIndex]}
                                                                style={{
                                                                    width: '130px', display: 'inline-block',
                                                                    marginTop: '10px', marginRight: '2.5%',
                                                                    marginLeft: '2.5%', cursor: 'pointer'
                                                                }}
                                                            />
                                                        )
                                                    })}
                                                </div>
                                            )
                                        })}
                                    </Carousel>
                                </div>
                                <div style={{ ...contentWraper, ...sectionDetail }}>
                                    {props.goodsAndSouvenir.map((data, index) => {
                                        return (
                                            <div key={`goodsTitle${index}`} >
                                                <h3 style={sectionDetailTitle}>{data[0].name_en.slice(8, (data[0].name_en.indexOf(' Subtitle')))}</h3>
                                                <div style={{ textAlign: 'center' }}>
                                                    <Divider style={contentDivider} />
                                                </div>
                                                {props.goodsAndSouvenir[index].map((d, i) => {
                                                    return (
                                                        <div key={`subGoodsTitle${i}`} >
                                                            <div style={{ width: '40%', display: 'inline-block', paddingLeft: '5%' }}>
                                                                <Radio style={radioItem} value={d.id}
                                                                    onClick={() => {
                                                                        props.selectTicketGoodsDeliveryhandeler(
                                                                            'Souvenir', d.name_en, d.id, 1,
                                                                            d.price, d.currency
                                                                        )
                                                                    }}
                                                                >
                                                                    {(d.name_en.slice(((d.name_en.indexOf(' Subtitle') + 10))))}
                                                                </Radio>
                                                            </div>
                                                            <div style={{ width: '20%', display: 'inline-block' }}>
                                                                {`${d.currency}  ${d.price}`}
                                                            </div>
                                                            { props.returnQuantity[d.id] ?
                                                                (<div style={{ width: '40%', display: 'inline-block' }}>
                                                                    <Buttontemp
                                                                        onClick={() => {
                                                                            props.selectTicketGoodsDeliveryhandeler(
                                                                                'Souvenir', d.name_en, d.id, 1,
                                                                                d.price, d.currency, 'minus'
                                                                            )
                                                                        }}
                                                                        style={{ display: 'inline-block', marginRight: '25px' }}
                                                                    >
                                                                        <h3>-</h3>
                                                                    </Buttontemp>{' '}
                                                                    <div style={{
                                                                        display: 'inline-block', border: '1px solid #c5c2c2', textAlign: 'center',
                                                                        borderRadius: '4px', width: '68px'
                                                                    }}
                                                                    >
                                                                        {props.returnQuantity[d.id]}
                                                                    </div>
                                                                    <Buttontemp
                                                                        onClick={() => {
                                                                            props.selectTicketGoodsDeliveryhandeler(
                                                                                'Souvenir', d.name_en, d.id, 1,
                                                                                d.price, d.currency, 'plus'
                                                                            )
                                                                        }}
                                                                        style={{ display: 'inline-block', marginLeft: '25px' }}
                                                                    >
                                                                        <h3>+</h3>
                                                                    </Buttontemp>
                                                                </div>) : null}
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        ) : null}
                        {/* Goods and souvenir */}
                        {/* Delivery */}
                        {((props.isOpen.Delivery === true) && (props.delivery.length > 0)) ? (
                            <div>
                                <div style={{ ...contentWraper, ...sectionTitle }} >
                                    <div>
                                        <Divider style={divider} type='vertical' />
                                        <h2 style={{ display: 'inline' }}>{props.locale['DELIVERY']}</h2>
                                    </div>
                                </div>
                                <div style={{ ...contentWraper, ...sectionDetail }}>
                                    {props.delivery.map((data, index) => {
                                        return (
                                            <div key={`deliveryTitle${index}`} >
                                                <h3 style={sectionDetailTitle}>{data[0].name_en.slice(8, (data[0].name_en.indexOf(' Subtitle')))}</h3>
                                                <div style={{ textAlign: 'center' }}>
                                                    <Divider style={contentDivider} />
                                                </div>
                                                <Radio.Group style={radioGroup}>
                                                    {props.delivery[index].map((d, i) => {
                                                        return (
                                                            <div key={`subDeliveryTitle${i}`} >
                                                                <div style={{ width: '40%', display: 'inline-block', paddingLeft: '5%' }}>
                                                                    <Radio value={d.id} style={radioItem}
                                                                        onClick={() => {
                                                                            console.log('Delivery Click')
                                                                            props.selectTicketGoodsDeliveryhandeler(
                                                                                'Delivery', d.name_en, d.id, 1,
                                                                                d.price, d.currency
                                                                            )
                                                                        }}
                                                                    >
                                                                        {(d.name_en.slice(((d.name_en.indexOf(' Subtitle') + 10))))}
                                                                    </Radio>
                                                                    {props.inputQuantity}
                                                                </div>
                                                                <div style={{ width: '60%', display: 'inline-block' }}>
                                                                    {`${d.currency}  ${d.price}`}
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </Radio.Group>
                                            </div>
                                        )
                                    })}
                                    {props.isOpen.DeliveryAddress === true  ? (
                                        <div style={{ paddingBottom: '20px' }}>
                                            <div style={inputItem}>
                                                {props.locale['DELIVERY_ADDRESS']}
                                            </div>
                                            <div style={inputItem}>
                                                <div style={{ width: '40px', display: 'inline-block', color: '#555555' }} >{props.locale['ADDRESS']}:</div>
                                                <Input
                                                    style={{ width: '65%', display: 'inline-block', marginRight: '15px', borderWidth: '0px 0px 1px 0px', borderRadius: '0px' }}
                                                    className='ant-input'
                                                    onChange={event => {
                                                        props.deliveryAndPaymentDetail('address', event.target.value)
                                                    }}
                                                />
                                                <div style={{ width: '40px', display: 'inline-block', color: '#555555' }} >{props.locale['TEL']}:</div>
                                                <Input
                                                    style={{ width: '20%', display: 'inline-block', borderWidth: '0px 0px 1px 0px', borderRadius: '0px' }}
                                                    className='ant-input'
                                                    onChange={event => {
                                                        props.deliveryAndPaymentDetail('tel', event.target.value)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        ) : null}
                        {/* Delivery */}
                        {/* Payment */}
                        {((props.isOpen.PaymentAndSubmit === true) || ((props.delivery.length <= 0 ) && (props.isOpen.Delivery === true))) ? (
                            <div>
                                <div style={{ ...contentWraper, ...sectionTitle }} >
                                    <div>
                                        <Divider style={divider} type='vertical' />
                                        <h2 style={{ display: 'inline' }}>{props.locale['SELECT_PAYMENT']}</h2>
                                    </div>
                                </div>
                                <div style={{ ...contentWraper, ...sectionDetail }}>
                                    <Radio.Group style={radioGroup}
                                        onChange={e => {
                                            props.deliveryAndPaymentDetail('payment', e.target.value)
                                        }}
                                    >
                                        <Radio.Button value='promptpay' style={radiobuttomItem} >
                                            <i style={{ fontSize: '50px', marginTop: '20px' }} className="fas fa-qrcode"></i>
                                            <br />
                                            <h4>{props.locale['PAYMENT_BY_QR_CODE']}</h4>
                                        </Radio.Button>
                                        <Radio.Button value='creditcard' style={radiobuttomItem} disabled={true}>
                                            <i style={{ fontSize: '50px', marginTop: '20px' }} className="far fa-credit-card"></i>
                                            <br />
                                            <h4>{props.locale['PATMENT_BY_CREDIT_CARD']}</h4>
                                        </Radio.Button>
                                    </Radio.Group>
                                </div>
                                {/* Payment */}
                            </div>
                        ) : null}
                        {/* Detail Section*/}
                        {props.isOpen.BuyTicket ?
                            (
                                <div style={{ ...contentWraper, ...buttonBuy }}>
                                    <Button.Button7 style={{ width: '30%' }}
                                        onClick={props.toggleOrderSummary}
                                    >
                                        {props.locale['BUY_TICKET']}
                                    </Button.Button7>
                                </div>
                            ) : null
                        }
                    </div>
                </div>
            </MediaQuery>
            {/* ---------------------------- Mobile Section ---------------------------- */}
            <MediaQuery maxWidth={768}>
                <div style={mainDisplay}>
                    <div style={mainConteneWrapperMobile}>
                        {/* Header Section*/}
                        <div style={{ ...contentWraper, ...{ marginBottom: '0' } }}>
                            <img
                                src={props.title.logo_img} alt="Image"
                                style={logoImage}
                            />
                        </div>
                        <div style={{ ...contentWraper, ...{ backgroundColor: 'white', marginTop: '-7%' } }}>
                            <div style={{ paddingTop: '60px', paddingBottom: '20px', textAlign: 'center', height: '180px', marginBottom: '20px', marginTop: '-40px' }}>
                                <h2 style={{ fontSize: '20px', color: '#555555', fontWeight: '600' }}> {(props.lang === 'EN') ? props.titleOriginal.title_en : props.titleOriginal.title_th} </h2>
                                <h1 style={{ fontSize: '20px', color: '#555555' }}> {(props.lang === 'EN') ? props.titleOriginal.description_en : props.titleOriginal.description_th} </h1>
                                <div>
                                    <i style={{ fontSize: '15px', marginRight: '20px', fontWeight: '500' }} className="fas fa-calendar-alt">
                                        <span
                                            style={{
                                                fontSize: '15px', marginLeft: '10px',
                                                fontFamily: 'SF Compact Display Medium, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol',
                                            }}>
                                            {datetimeformater(props.titleOriginal.date, props.lang.toLowerCase(), 'date')}
                                        </span>
                                    </i>
                                    <i style={{ fontSize: '15px' }} className="far fa-clock">
                                        <span
                                            style={{
                                                fontSize: '15px', marginLeft: '10px',
                                                fontFamily: 'SF Compact Display Medium, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol',
                                            }}>
                                            {datetimeformater(props.titleOriginal.date, props.lang.toLowerCase(), 'time')}
                                        </span>
                                    </i>
                                </div>
                            </div>
                        </div>
                        {/* Header Section*/}
                        {/* Detail Section*/}
                        {/* Ticket */}
                        { props.ticket.length > 0 ?
                        (
                        <div>
                            <div style={{ ...contentWraper, ...sectionTitle }} >
                                <div>
                                    <Divider style={dividerMobile} type='vertical' />
                                    <h2 style={{ display: 'inline', fontSize: '20px' }}>{props.locale['TICKET_DETAIL']}</h2>
                                </div>
                            </div>
                            <div style={{ ...contentWraper, ...sectionDetail }}>
                                {props.ticket.map((data, index) => {
                                    return (
                                        <div key={`ticketTitle${index}`} >
                                            <h3 style={sectionDetailTitle}>{data[0].name_en.slice(8, (data[0].name_en.indexOf(' Subtitle')))}</h3>
                                            <div style={{ textAlign: 'center' }}>
                                                <Divider style={contentDivider} />
                                            </div>
                                            <Radio.Group style={radioGroup}>
                                                {props.ticket[index].map((d, i) => {
                                                    return (
                                                        <div key={`subTicketTitle${i}`}>
                                                            <div style={{ width: '40%', display: 'inline-block', paddingLeft: '5%' }}>
                                                                <Radio value={d.id} style={radioItem}
                                                                    onClick={() => {
                                                                        props.selectTicketGoodsDeliveryhandeler(
                                                                            'Ticket', d.name_en, d.id, 1,
                                                                            d.price, d.currency
                                                                        )
                                                                    }}
                                                                >
                                                                    {(d.name_en.slice(((d.name_en.indexOf(' Subtitle') + 10))))}
                                                                </Radio>
                                                            </div>
                                                            <div style={{ width: '60%', display: 'inline-block' }}>
                                                                {`${d.currency}  ${d.price}`}
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </Radio.Group>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        ) : null }
                        {/* Ticket */}
                        {/* Goods and souvenir */}
                        {((props.isOpen.GoodsAndSouvenir === true) || props.ticket.length <= 0) ? (
                            <div>
                                <div style={{ ...contentWraper, ...sectionTitle }} >
                                    <div>
                                        <Divider style={dividerMobile} type='vertical' />
                                        <h2 style={{ display: 'inline', fontSize: '20px' }}>{props.locale['GOODS_AND_SOUVENIR']}</h2>
                                    </div>
                                </div>
                                <div style={pictureWraper}>
                                    <Carousel style={{ textAlign: 'center', height: '180px' }}>
                                        {props.souvenirImageMobile.map((data, index) => {
                                            return (
                                                <div key={`souvenirImageMobile${index}`} >
                                                    {props.souvenirImageMobile[index].map((d, i) => {
                                                        let imageIndex = Object.keys(d)
                                                        return (
                                                            <img
                                                                key={`image${i}`}
                                                                onClick={() => {
                                                                    let fileImageURL = { url: d[imageIndex] }
                                                                    props.onPreview(fileImageURL)
                                                                }}
                                                                src={d[imageIndex]}
                                                                style={{
                                                                    width: '130px', display: 'inline-block',
                                                                    marginTop: '10px', marginRight: '2.5%',
                                                                    marginLeft: '2.5%', cursor: 'pointer'
                                                                }}
                                                            />
                                                        )
                                                    })}
                                                </div>
                                            )
                                        })}
                                    </Carousel>
                                </div>
                                <div style={{ ...contentWraper, ...sectionDetail }}>
                                    {props.goodsAndSouvenir.map((data, index) => {
                                        return (
                                            <div key={`goodsTitle${index}`} >
                                                <h3 style={sectionDetailTitle}>{data[0].name_en.slice(8, (data[0].name_en.indexOf(' Subtitle')))}</h3>
                                                <div style={{ textAlign: 'center' }}>
                                                    <Divider style={contentDivider} />
                                                </div>
                                                {props.goodsAndSouvenir[index].map((d, i) => {
                                                    return (
                                                        <div key={`subGoodsTitle${i}`} >
                                                            <div style={{ width: '40%', display: 'inline-block', paddingLeft: '5%' }}>
                                                                <Radio style={radioItem} value={d.id}
                                                                    onClick={() => {
                                                                        props.selectTicketGoodsDeliveryhandeler(
                                                                            'Souvenir', d.name_en, d.id, 1,
                                                                            d.price, d.currency
                                                                        )
                                                                    }}
                                                                >
                                                                    {(d.name_en.slice(((d.name_en.indexOf(' Subtitle') + 10))))}
                                                                </Radio>
                                                            </div>
                                                            <div style={{ width: '20%', display: 'inline-block' }}>
                                                                {`${d.currency}  ${d.price}`}
                                                            </div>
                                                            { props.returnQuantity[d.id] ?
                                                                (<div style={{ width: '40%', display: 'inline-block' }}>
                                                                    <Buttontemp
                                                                        onClick={() => {
                                                                            props.selectTicketGoodsDeliveryhandeler(
                                                                                'Souvenir', d.name_en, d.id, 1,
                                                                                d.price, d.currency, 'minus'
                                                                            )
                                                                        }}
                                                                        style={{ display: 'inline-block', marginRight: '20px' }}
                                                                    >
                                                                        <h3>-</h3>
                                                                    </Buttontemp>{' '}
                                                                    <div style={{
                                                                        display: 'inline-block', border: '1px solid #c5c2c2', textAlign: 'center',
                                                                        borderRadius: '4px', width: '50px'
                                                                    }}
                                                                    >
                                                                        {props.returnQuantity[d.id]}
                                                                    </div>
                                                                    <Buttontemp
                                                                        onClick={() => {
                                                                            props.selectTicketGoodsDeliveryhandeler(
                                                                                'Souvenir', d.name_en, d.id, 1,
                                                                                d.price, d.currency, 'plus'
                                                                            )
                                                                        }}
                                                                        style={{ display: 'inline-block', marginLeft: '20px' }}
                                                                    >
                                                                        <h3>+</h3>
                                                                    </Buttontemp>
                                                                </div>) : null}
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        ) : null}
                        {/* Goods and souvenir */}
                        {/* Delivery */}
                        {((props.isOpen.Delivery === true) && (props.delivery.length > 0)) ? (
                            <div>
                                <div style={{ ...contentWraper, ...sectionTitle }} >
                                    <div>
                                        <Divider style={dividerMobile} type='vertical' />
                                        <h2 style={{ display: 'inline', fontSize: '20px' }}>{props.locale['DELIVERY']}</h2>
                                    </div>
                                </div>
                                <div style={{ ...contentWraper, ...sectionDetail }}>
                                    {props.delivery.map((data, index) => {
                                        return (
                                            <div key={`deliveryTitle${index}`} >
                                                <h3 style={sectionDetailTitle}>{data[0].name_en.slice(8, (data[0].name_en.indexOf(' Subtitle')))}</h3>
                                                <div style={{ textAlign: 'center' }}>
                                                    <Divider style={contentDivider} />
                                                </div>
                                                <Radio.Group style={radioGroup}>
                                                    {props.delivery[index].map((d, i) => {
                                                        return (
                                                            <div key={`subDeliveryTitle${i}`} >
                                                                <div style={{ width: '40%', display: 'inline-block', paddingLeft: '5%' }}>
                                                                    <Radio value={d.id} style={radioItem}
                                                                        onClick={() => {
                                                                            console.log('Delivery Click')
                                                                            props.selectTicketGoodsDeliveryhandeler(
                                                                                'Delivery', d.name_en, d.id, 1,
                                                                                d.price, d.currency
                                                                            )
                                                                        }}
                                                                    >
                                                                        {(d.name_en.slice(((d.name_en.indexOf(' Subtitle') + 10))))}
                                                                    </Radio>
                                                                    {props.inputQuantity}
                                                                </div>
                                                                <div style={{ width: '60%', display: 'inline-block' }}>
                                                                    {`${d.currency}  ${d.price}`}
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </Radio.Group>
                                            </div>
                                        )
                                    })}
                                    {props.isOpen.DeliveryAddress === true ? (
                                        <div style={{ paddingBottom: '20px' }}>
                                            <div style={inputItem}>
                                                {props.locale['DELIVERY_ADDRESS']}
                                            </div>
                                            <div style={inputItem}>
                                                <div style={{ width: '40px', display: 'inline-block', color: '#555555' }} >{props.locale['ADDRESS']}:</div>
                                                <Input
                                                    style={{ width: '80%', display: 'inline-block', marginRight: '15px', borderWidth: '0px 0px 1px 0px', borderRadius: '0px' }}
                                                    className='ant-input'
                                                    onChange={event => {
                                                        props.deliveryAndPaymentDetail('address', event.target.value)
                                                    }}
                                                />
                                                <div style={{ width: '40px', display: 'inline-block', color: '#555555' }} >{props.locale['TEL']}:</div>
                                                <Input
                                                    style={{ width: '40%', display: 'inline-block', borderWidth: '0px 0px 1px 0px', borderRadius: '0px' }}
                                                    className='ant-input'
                                                    onChange={event => {
                                                        props.deliveryAndPaymentDetail('tel', event.target.value)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        ) : null}
                        {/* Delivery */}
                        {/* Payment */}
                        {((props.isOpen.PaymentAndSubmit === true) || ((props.delivery.length <= 0 ) && (props.isOpen.Delivery === true))) ? (
                            <div>
                                <div style={{ ...contentWraper, ...sectionTitle }} >
                                    <div>
                                        <Divider style={dividerMobile} type='vertical' />
                                        <h2 style={{ display: 'inline', fontSize: '20px' }}>{props.locale['SELECT_PAYMENT']}</h2>
                                    </div>
                                </div>
                                <div style={{ ...contentWraper, ...sectionDetail }}>
                                    <Radio.Group style={radioGroupMobile}
                                        onChange={e => {
                                            props.deliveryAndPaymentDetail('payment', e.target.value)
                                        }}
                                    >
                                        <Radio.Button value='promptpay' style={radiobuttomItemMobile}>
                                            <i style={{ fontSize: '40px', marginTop: '10px' }} className="fas fa-qrcode"></i>
                                            <br />
                                            <h4>{props.locale['PAYMENT_BY_QR_CODE']}</h4>
                                        </Radio.Button>
                                        <Radio.Button value='creditcard' style={radiobuttomItemMobile} disabled={true}>
                                            <i style={{ fontSize: '40px', marginTop: '10px' }} className="far fa-credit-card"></i>
                                            <br />
                                            <h4>{props.locale['PATMENT_BY_CREDIT_CARD']}</h4>
                                        </Radio.Button>
                                    </Radio.Group>
                                </div>
                                {/* Payment */}
                            </div>
                        ) : null}
                        {/* Detail Section*/}
                        {props.isOpen.BuyTicket ?
                            (
                                <div style={{ ...contentWraper, ...buttonBuy }}>
                                    <Button.Button7 style={{ width: '100%' }}
                                        onClick={props.toggleOrderSummary}
                                    >
                                        {props.locale['BUY_TICKET']}
                                    </Button.Button7>
                                </div>
                            ) : null
                        }
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}
