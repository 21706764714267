import React from 'react'
import s from 'styled-components'

import { getLanguage } from '../../locale'
import {
  Modal,
  ModalCloseConfirm,
  ModalContent,
  ModalHeaderNoTitle,
} from './index'
import { getShowDate } from '../../utils/string'

const Body = s.div`text-align:center;padding: 10px;`
const LogoImage = s.img`width: 70px;height: 70px;border-radius: 50px;-webkit-box-shadow: 0 2px 4px;box-shadow: 0 2px 4px;cursor:pointer;margin-bottom:10px;`

function ModalConfirmJoinFree(props) {
  const lang = getLanguage().lang

  return (
    <Modal id="modalConfirmJoinFree">
      <ModalContent maxWidth={400}>
        <ModalHeaderNoTitle id="modalConfirmJoinFree" />
        <Body>
          <LogoImage src={props.data.logo} alt="" />
          <p>{props.locale.ARE_YOUR_GOING_JOIN}</p>
          <p>{lang === 'TH' ? props.data.title_th : props.data.title_en}</p>
          <p>
            {props.locale.ON.toLowerCase()} {getShowDate(lang, props.data.date)}{' '}
            ?
          </p>
        </Body>
        <ModalCloseConfirm
          id="modalConfirmJoinFree"
          onClick={props.onJoinEvent}
          textYes={props.locale.YES}
          textNo={props.locale.NO}
        />
      </ModalContent>
    </Modal>
  )
}

export default ModalConfirmJoinFree
