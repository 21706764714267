import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'
import Button from '../form/buttons'
import Modal from 'react-modal'
import IconClose from '../../images/ic_close.svg'
import QRCode from 'qrcode.react'
import MediaQuery from 'react-responsive'
import Countdown from '../countdown/countdown'

const ColumnHeader = styled.div`
  opacity: 0.87;
  font-family: RSU-Bold;
  font-size: 24px;
  color: #3f3131;
`

const Line = styled.hr`
  border: 0;
  height: 1px;
  background: #f0f0f1;
  margin: ${({ margin = 20 }) => `${margin}px 0`};
`

const LineColumnTable = styled(Line)`
  background: #3f3131;
  margin: 12px 0;
`

const ColumnContainer = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;

  div:nth-child(1) {
    flex: 2;
  }
  div:not(:first-child) {
    flex: 1;
  }
`
const ColumnContainerMobile = styled.div`
  display: flex;
  justify-content: center;
  text-align: left;

  div:nth-child(1) {
    flex: 2;
  }
  div:not(:first-child) {
    flex: 1;
  }
`

const RowContainer = styled.div`
  display: flex;
  text-align: center;

  div:nth-child(1) {
    flex: 2;
    text-align: left;
  }
  div:not(:first-child) {
    flex: 1;
  }
`

const RowValue = styled.div`
  opacity: 0.87;
  font-size: 16px;
  color: #3f3131;
  letter-spacing: 0.5px;
  line-height: 28px;
`
const RowValueMobile = styled.div`
  opacity: 0.87;
  font-size: 16px;
  font-weight: 600;
  color: #3f3131;
  letter-spacing: 0.5px;
  line-height: 28px;
  text-align: left;
  margin-top: 25px;
`

const RowTotal = styled(RowContainer)`
  margin-top: 32px;

  div:nth-child(4) {
    font-family: RSU-Bold;
    font-size: 20px;
    color: #615251;
    letter-spacing: 0.25px;
  }
`

const RowVat = styled(RowContainer)`
  div:nth-child(4) {
    font-size: 20px;
    color: #6b5d5b;
    letter-spacing: 0.25px;
  }
`

const RowGrandTotal = styled(RowContainer)`
  div {
    opacity: 0.87;
    font-family: RSU-Bold;
    font-size: 24px;
    color: #3f3131;
  }
`
const RowGrandTotalMobile = styled(RowContainer)`
  div {
    opacity: 0.87;
    font-size: 20px;
    color: #3f3131;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const ModalTotal = styled.div`
  opacity: 0.87;
  font-family: RSU-Bold;
  font-size: 34px;
  color: #3f3131;
  letter-spacing: 0.25px;
  text-align: center;
`

const ModalHowtopay = styled.div`
  opacity: 0.87;
  font-family: RSU-Bold;
  font-size: 20px;
  color: #3f3131;
  letter-spacing: 0.25px;
`

const ModalPayStep = styled.div`
  opacity: 0.87;
  font-family: RSU;
  font-size: 20px;
  color: #938a87;
  letter-spacing: 0.25px;
`

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
`

export default class ConfirmTicket extends Component {
  state = {
    checked: false,
    showModal: false,
    deadline: 0
  }
  formatNumber = (num) => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  render() {
    const { checked } = this.state
    const { onBack } = this.props
    let total = 0
    let totalmobile = 0
    return (
      <Fragment>
        <div className="club__header">
          <hr className="club__line" />
          <h3 style={this.styleFont1}>{this.props.locale['ORDER_SUMMARY']}</h3>
        </div>
        <div className="club__card" style={{ minHeight: 244, marginTop: 20 }}>
          <div style={{ marginBottom: 32 }}>
            <ColumnHeader>
              {this.props.locale['REVIEW_ORDER_SUMMARY']}
            </ColumnHeader>
            <Line />

            <div className="flex flex-column-mobile">
              <div className="flex-auto mg-r-40">
                <p className="information__label">
                  {this.props.locale['NAME_AND_FAMILY_NAME']}
                </p>
                <p className="information__value">
                  {this.props.invitreeRegister.name ? this.props.invitreeRegister.name : this.props.clubRegister.user_info.firstname + '  ' + this.props.clubRegister.user_info.lastname}
                </p>
              </div>
              <div className="flex-auto">
                <p className="information__label">
                  {this.props.locale['E_MAIL']}
                </p>
                <p className="information__value">{this.props.invitreeRegister.email}</p>
              </div>
            </div>
            <div className="flex flex-column-mobile">
              <div className="flex-auto mg-r-40">
                <p className="information__label">
                  {this.props.locale['PAYMENT']}
                </p>
                <p className="information__value">
                  {(this.props.paymentMethod === 'promptpay') ? this.props.locale['PAYMENT_BY_QR_CODE'] : this.props.locale['PATMENT_BY_CREDIT_CARD']}
                </p>
              </div>
              <div className="flex-auto">
                <p className="information__label">
                  {this.props.locale['DELIVERY_ADDRESS']}
                </p>
                <p className="information__value">
                  {this.props.delivery.address + ' ' + this.props.locale['TEL'] + '. ' + this.props.delivery.tel}
                </p>
              </div>
            </div>
            <div style={{ width: '100%', overflowX: 'scroll' }}>
              <MediaQuery minWidth={769}>
                <div style={{ minWidth: 700 }}>
                  <LineColumnTable />
                  <ColumnContainer>
                    <div>{this.props.locale['ITEM']}</div>
                    <div>{this.props.locale['PRICE']}</div>
                    <div>{this.props.locale['FEE']}</div>
                    <div>{this.props.locale['QUANTITY']}</div>
                    <div>{this.props.locale['SUBTOTAL']}</div>
                  </ColumnContainer>
                  <LineColumnTable />
                  {/* Ticket Section */}
                  {this.props.selectOrderTicket[0].title ?
                    (
                      this.props.selectOrderTicket.map((data, index) => {
                        total = total + (data.price * data.quantity)
                        return (
                          <RowContainer key={`Ticket${index}`}>
                            <RowValue>
                              {data.title.slice(8, (data.title.indexOf(' Subtitle'))) + data.title.slice(((data.title.indexOf(' Subtitle') + 10)))}
                            </RowValue>
                            <RowValue>
                              ฿{this.formatNumber(data.price)}.00
                      </RowValue>
                            <RowValue>฿0.00</RowValue>
                            <RowValue>
                              {data.quantity}
                            </RowValue>
                            <RowValue>฿{this.formatNumber((parseFloat(data.price) * data.quantity).toFixed(2))}</RowValue>
                          </RowContainer>
                        )
                      })
                    ) : null
                  }
                  {/* Ticket Section */}
                  {/* Souvenir Section */}
                  {this.props.selectOrderSouvenir[0].title ?
                    (
                      (this.props.selectOrderSouvenir.map((data, index) => {
                        total = total + (data.price * data.quantity)
                        return (
                          <RowContainer key={`Souvenir${index}`}>
                            <RowValue>
                              {data.title.slice(8, (data.title.indexOf(' Subtitle'))) + '  ' + data.title.slice(((data.title.indexOf(' Subtitle') + 10)))}
                            </RowValue>
                            <RowValue>
                              ฿{this.formatNumber(data.price)}.00
                          </RowValue>
                            <RowValue>฿0.00</RowValue>
                            <RowValue>
                              {data.quantity}
                            </RowValue>
                            <RowValue>฿{this.formatNumber((parseFloat(data.price) * data.quantity).toFixed(2))}</RowValue>
                          </RowContainer>
                        )
                      }))
                    ) : null}
                  {/* Souvenir Section */}
                  {/* Delivery Section */}
                  {this.props.selectOrderDelivery[0].title ?
                    (this.props.selectOrderDelivery.map((data, index) => {
                      total = total + (data.price * data.quantity)
                      return (
                        <RowContainer key={`Delivery${index}`}>
                          <RowValue>
                            {data.title.slice(8, (data.title.indexOf(' Subtitle'))) + '  ' + data.title.slice(((data.title.indexOf(' Subtitle') + 10)))}
                          </RowValue>
                          <RowValue>
                            ฿{this.formatNumber(data.price)}.00
                      </RowValue>
                          <RowValue>฿0.00</RowValue>
                          <RowValue>
                            {data.quantity}
                          </RowValue>
                          <RowValue>
                            ฿{this.formatNumber((parseFloat(data.price) * data.quantity).toFixed(2))}
                          </RowValue>
                        </RowContainer>
                      )
                    })) : null
                  }
                  {/* Delivery Section */}
                  <RowTotal>
                    <RowValue />
                    <RowValue />
                    <RowValue />
                    <RowValue>{this.props.locale['TOTAL']}</RowValue>
                    <RowValue>฿{(this.formatNumber(total.toFixed(2)))}</RowValue>
                  </RowTotal>
                  <Line margin={16} />
                  <RowVat>
                    <RowValue />
                    <RowValue />
                    <RowValue />
                    <RowValue>{this.props.locale['SERVICE_CHARGE']}</RowValue>
                    <RowValue>฿0.00</RowValue>
                  </RowVat>
                  <Line margin={16} />
                  {/* Wait for VAT implementation */}
                  {/* <RowVat>
                  <RowValue />
                  <RowValue />
                  <RowValue />
                  <RowValue>{this.props.locale['VAT']}</RowValue>
                  <RowValue>฿{this.formatNumber((total * 7 / 100).toFixed(2))}</RowValue>
                </RowVat> */}
                  <Line margin={16} />
                  <RowGrandTotal>
                    <RowValue />
                    <RowValue />
                    <RowValue />
                    <RowValue>{this.props.locale['GRAND_TOTAL']}</RowValue>
                    <RowValue>฿{this.formatNumber(total.toFixed(2))}</RowValue>
                  </RowGrandTotal>
                  <LineColumnTable />
                </div>
              </MediaQuery>
              <MediaQuery maxWidth={768}>
                <LineColumnTable />
                <ColumnContainerMobile>
                  <div>{this.props.locale['ITEM']}</div>
                  <div>{this.props.locale['SUBTOTAL']}</div>
                </ColumnContainerMobile>
                <LineColumnTable />
                {/* Ticket Section */}
                {this.props.selectOrderTicket[0].title ?
                  (
                    this.props.selectOrderTicket.map((data, index) => {
                      totalmobile = totalmobile + (data.price * data.quantity)
                      return (
                        <RowContainer key={`Ticket${index}`}>
                          <RowValueMobile>
                            <div>
                              <div>{data.title.slice(8, (data.title.indexOf(' Subtitle'))) + data.title.slice(((data.title.indexOf(' Subtitle') + 10)))}</div>
                              <div>฿{this.formatNumber(data.price)}.00 x {data.quantity}</div>
                              {/* <div>{this.props.locale['FEE']}฿0.00</div> */}
                            </div>
                          </RowValueMobile>
                          <RowValueMobile>
                            ฿{this.formatNumber((parseFloat(data.price) * data.quantity).toFixed(2))}
                          </RowValueMobile>
                        </RowContainer>
                      )
                    })
                  ) : null
                }
                {/* Ticket Section */}
                {/* Souvenir Section */}
                {this.props.selectOrderSouvenir[0].title ?
                  (
                    (this.props.selectOrderSouvenir.map((data, index) => {
                      totalmobile = totalmobile + (data.price * data.quantity)
                      return (
                        <RowContainer key={`Souvenir${index}`}>
                          <RowValueMobile>
                            <div>
                              <div>{data.title.slice(8, (data.title.indexOf(' Subtitle'))) + data.title.slice(((data.title.indexOf(' Subtitle') + 10)))}</div>
                              <div>฿{this.formatNumber(data.price)}.00 x {data.quantity}</div>
                              {/* <div>{this.props.locale['FEE']}฿0.00</div> */}
                            </div>
                          </RowValueMobile>
                          <RowValueMobile>
                            ฿{this.formatNumber((parseFloat(data.price) * data.quantity).toFixed(2))}
                          </RowValueMobile>
                        </RowContainer>
                      )
                    }))
                  ) : null}
                {/* Souvenir Section */}
                {/* Delivery Section */}
                {this.props.selectOrderDelivery[0].title ?
                  (this.props.selectOrderDelivery.map((data, index) => {
                    totalmobile = totalmobile + (data.price * data.quantity)
                    return (
                      <RowContainer key={`Delivery${index}`}>
                        <RowValueMobile>
                          <div>
                            <div>{data.title.slice(8, (data.title.indexOf(' Subtitle'))) + data.title.slice(((data.title.indexOf(' Subtitle') + 10)))}</div>
                            <div>฿{this.formatNumber(data.price)}.00</div>
                            {/* <div>{this.props.locale['FEE']}฿0.00</div> */}
                          </div>
                        </RowValueMobile>
                        <RowValueMobile>
                          ฿{this.formatNumber(parseFloat(data.price).toFixed(2))}
                        </RowValueMobile>
                      </RowContainer>
                    )
                  })) : null
                }
                {/* Delivery Section */}
                <RowTotal>
                  <RowValueMobile>{this.props.locale['TOTAL']}</RowValueMobile>
                  <RowValueMobile>฿{this.formatNumber(totalmobile.toFixed(2))}</RowValueMobile>
                </RowTotal>
                <Line margin={4} />
                <RowVat>
                  <RowValueMobile>{this.props.locale['SERVICE_CHARGE']}</RowValueMobile>
                  <RowValueMobile>฿0.00</RowValueMobile>
                </RowVat>
                {/* Wait for VAT implementation */}
                {/* <Line margin={16} /> */}
                {/* <RowVat>
                  <RowValue />
                  <RowValue />
                  <RowValue />
                  <RowValue>{this.props.locale['VAT']}</RowValue>
                  <RowValue>฿{this.formatNumber((total * 7 / 100).toFixed(2))}</RowValue>
                </RowVat> */}
                <Line margin={16} />
                <RowGrandTotalMobile>
                  <RowValueMobile>{this.props.locale['GRAND_TOTAL']}</RowValueMobile>
                  <RowValueMobile>฿{this.formatNumber(total.toFixed(2))}</RowValueMobile>
                </RowGrandTotalMobile>
                <LineColumnTable />
              </MediaQuery>
            </div>

            <div style={{ marginTop: 32 }}>
              <label
                className={`checkbox-label flex-auto ${checked === 'retired' ? 'checkbox-label--disabled' : ''
                  }`}
                style={{ fontSize: 20 }}
              >
                <MediaQuery minWidth={769}>
                  <div>
                    {this.props.locale['WARNING_REFUND_CONDITION']}
                  </div>
                </MediaQuery>
                <MediaQuery maxWidth={768}>
                  <div style={{ fontSize: '16px' }}>
                    {this.props.locale['WARNING_REFUND_CONDITION']}
                  </div>
                </MediaQuery>
                <input
                  type="checkbox"
                  checked={checked}
                  onChange={() => {
                    this.props.getAmount((total * 7 / 100).toFixed(2))
                    this.setState({ checked: !checked })
                  }}
                />
                <span
                  className={`checkmark ${checked === 'retired' ? 'checkmark--disabled' : ''
                    }`}
                />
              </label>
            </div>

            <ButtonContainer>
              <Button.Button8
                style={{
                  padding: 0,
                  width: 160,
                  height: 48,
                  margin: '48px 12px 0 12px'
                }}
                onClick={this.props.toggleOrderSummary}
              >
                {this.props.locale['BACK_TO_EDIT']}
              </Button.Button8>
              <Button.Button7
                style={{
                  padding: 0,
                  width: 160,
                  height: 48,
                  margin: '48px 12px 0 12px'
                }}
                onClick={() => {
                  if (this.state.checked) {
                    console.log('click')
                    if (this.props.paymentMethod === 'creditcard') {
                      this.props.onSubmit(this.props.paymentMethod)
                    } else {
                      this.props.onSubmit(this.props.paymentMethod)
                      let tempdeadline = this.props.configDeadline()
                      if (this.state.deadline == 0) {
                        this.setState({ deadline: tempdeadline, showModal: true })
                      } else { this.setState({ showModal: true }) }
                    }
                  }
                }}
                disabled={!this.state.checked}
              >
                {this.props.locale['PAY_NOW']}
              </Button.Button7>
            </ButtonContainer>
          </div>
        </div>
        <Modal
          isOpen={this.props.qrPaymentStatus.waitQrPayment && this.state.showModal}
          onRequestClose={() => this.setState({ showModal: false })}
          className="information__modal"
          overlayClassName="information__modal__overlay"
        >
          <div style={{ fontFamily: 'RSU' }}>
            <img
              src={IconClose}
              alt=""
              className="information__modal__close"
              onClick={() => this.setState({ showModal: false })}
            />

            <ModalTotal>
              <MediaQuery minWidth={769}>
                {this.props.lang === 'EN' ?
                  (this.props.locale['TOTAL_PRICE'] + ' ' + this.formatNumber(parseFloat(this.props.qrPaymentStatus.qrAmount).toFixed(2)) + ' THB')
                  :
                  (this.props.locale['TOTAL_PRICE'] + ' ' + this.formatNumber(parseFloat(this.props.qrPaymentStatus.qrAmount).toFixed(2)) + ' บาท')
                }
              </MediaQuery>
              <MediaQuery maxWidth={768}>
                <div style={{ fontSize: '24px' }}>
                  {this.props.lang === 'EN' ?
                    (this.props.locale['TOTAL_PRICE'] + ' ' + this.formatNumber(parseFloat(this.props.qrPaymentStatus.qrAmount).toFixed(2)) + 'THB')
                    :
                    (this.props.locale['TOTAL_PRICE'] + ' ' + this.formatNumber(parseFloat(this.props.qrPaymentStatus.qrAmount).toFixed(2)) + 'บาท')
                  }
                </div>
              </MediaQuery>
            </ModalTotal>
            <div style={{ textAlign: 'left', width: '100%', marginTop: 20 }}>
              <ModalHowtopay>
                <MediaQuery minWidth={769}>
                  {this.props.locale['HOW_TO_PAY_WITH']} QR Code
              </MediaQuery>
                <MediaQuery maxWidth={768}>
                  <div style={{ textAlign: 'center', width: '100%', marginTop: 20, fontSize: '18px' }}>
                    {this.props.locale['HOW_TO_PAY_WITH']} QR Code
                </div>
                </MediaQuery>
              </ModalHowtopay>
              <ModalPayStep>
                <MediaQuery minWidth={769}>
                  <div> {this.props.locale['SCAN_AND_PAY_DESKTOP']}</div>
                </MediaQuery>
                <MediaQuery maxWidth={768}>
                  <div style={{ fontSize: '18px', textAlign: 'center' }} > {this.props.locale['SAVE_AND_PAY_MOBILE']}</div>
                </MediaQuery>
              </ModalPayStep>
              <div style={{ textAlign: 'center', marginTop: '15px' }}>
                <img
                  src={this.props.qrPaymentStatus.qrImage}
                />
              </div>
            </div>
            <Countdown
              deadline={this.state.deadline}
              locale={this.props.locale}
              onPaymentExpire={this.props.onPaymentExpire}
            />
            {/* <ModalButtonContainer>
              <MediaQuery maxWidth={768}>
                <Button.Button8
                  style={{ padding: 0, marginRight: 6, width: 164 }}
                // onClick={() => this.setState({ showModal: false })}
                >
                  {this.props.locale['SAVE_QR_CODE']}
                </Button.Button8>
              </MediaQuery>
              <Button.Button7
                style={{ padding: 0, marginRight: 6, width: 164 }}
                onClick={() => { }}
              >
                {this.props.locale['COMPLETED']}
              </Button.Button7>
            </ModalButtonContainer> */}
          </div>
        </Modal>
      </Fragment>
    )
  }
}
