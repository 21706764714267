import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { navigate } from 'gatsby-link'
import swal from 'sweetalert'
import queryString from 'query-string'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Loading from '../components/loading'
import moment from 'moment'
// import View from '../components/presentation/buy-ticket'
import View from '../components/presentation/buy-ticket-goods'
import OrderSummary from '../components/presentation/confirm-ticket'
import { Modal } from 'antd'
import ModalConfirmJoinFree from '../components/popup/event-detail-confirm-join-free'
import { getProfileFromId, getProfile, createOrderAPI, orderChargeAPI, getOrderAPI } from '../utils/api'
import authActions from '../state/auth/actions'
import paymentActions from '../state/payment/actions'
import ModalHandler from '../utils/modal'
import homeActions from '../state/home/actions'
import clubRegisterActions from '../state/club-register/actions'
import Cookies from 'universal-cookie'
import { intersection } from 'lodash'
const cookies = new Cookies()

class BuyTicketAmount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      readyPageLogin: false,
      join_type: '',
      allDelivery: [],
      allTicket: [],
      rearrangeTicket: [],
      rearrangeSouvenir: [],
      souvenirImage: [],
      souvenirImageMobile: [],
      allSouvenir: [],
      selectOrderTicket: {
        //id: 0 
      },
      selectOrderSouvenir: [],
      selectOrderDelivery: {
        //id: 0 
      },
      isOpenGoodsAndSouvenir: false,
      isOpenDelivery: false,
      isOpenPaymentAndSubmit: false,
      isOpenDeliveryAddress: false,
      isOpenBuyTicket: false,
      isOpenOrderSummary: false,
      returnGoodsAndSouvenirQuantity: {},
      deliveryAddress: '',
      tel: '',
      paymentMethod: '',
      finalAmount: 0.00,
      waitQrPayment: false,
      qrImage: '',
      qrAmount: 0.00,
      orderID: '',
      orderPayload: {},
      previewVisible: false,
      previewImage: '',
    }
    this.handleCheckProfile = this.handleCheckProfile.bind(this)
    this.requestGoodsByEventIdhandeler = this.requestGoodsByEventIdhandeler.bind(this)
    this.extractTicketAndGoodsHandler = this.extractTicketAndGoodsHandler.bind(this)
    this.requestDeliveryByEventIdhandeler = this.requestDeliveryByEventIdhandeler.bind(this)
    this.selectTicketGoodsDeliveryhandeler = this.selectTicketGoodsDeliveryhandeler.bind(this)
    this.deliveryAndPaymentDetail = this.deliveryAndPaymentDetail.bind(this)
    this.toggleOrderSummary = this.toggleOrderSummary.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.getAmount = this.getAmount.bind(this)
    this.configDeadline = this.configDeadline.bind(this)
    this.checkPaymentStatus = this.checkPaymentStatus.bind(this)
    this.sleep = this.sleep.bind(this)
    this.onPaymentExpire = this.onPaymentExpire.bind(this)
    this.prepareGoods = this.prepareGoods.bind(this)
    this.onPreview = this.onPreview.bind(this)
    this.closePreview = this.closePreview.bind(this)
  }

  componentDidMount() {
    this.props.checkAuthorization(null, '/', null, {
      onSuccess: () => {
        const params = queryString.parse(this.props.location.search)
        // Remove old componentDidMount (Can delete after develop new payment complete)
        // if (params.package_id === '1' && params.type === 'buy_package') {
        //   let eventID = ''
        //   if (params.event_id) {
        //     eventID = `&event_id=${params.event_id}`
        //   }
        //   navigate(
        //     `/payment/?package_id=${params.package_id}&type=${
        //       params.type
        //     }${eventID}`
        //   )
        // } else if (params.join_type === 'free') {
        //   this.props.onSubmitAmountTicket({
        //     ...params,
        //     join_type: 'free',
        //     amount: 0,
        //     currency: 'thb',
        //     blessing: '',
        //   })
        // } else {
        //   const join_type = params.join_type
        //   this.setState({ readyPageLogin: true, join_type })
        // }
        this.props.onLoad(params.event_id, 'viewDetail')
        this.requestGoodsByEventIdhandeler(params.event_id)
        this.handleCheckProfile()
      },
    })
  }

  handleCheckProfile = async () => {
    const resData = await getProfile()
    if (resData.data && resData.data.username) {
      const userData = await getProfileFromId(resData.data.username)
      if (userData && userData.data && userData.data._id) {
        cookies.set('_id', userData.data._id, {
          path: '/',
          expires: new Date(
            moment()
              .add(1, 'day')
              .format()
          ),
        })
        this.props.onSetProfile({
          ...userData.data,
        })
      }
      // remove because need only get profile 
      // else {
      //   navigate(`/home`)
      // }
    }
  }

  onSubmit = async (paymentType) => {
    const params = queryString.parse(this.props.location.search)
    const locale = this.props.app.locale[this.props.app.lang]
    if (paymentType === 'creditcard') {
      if (params.package_id && params.type && params.event_id) {
        const amount = parseFloat(this.state.finalAmount)
        if (isNaN(amount)) {
          swal(locale.ERROR, locale.WARNING_AMOUNT_EMPTY, 'error')
        } else if (amount <= 0) {
          swal(locale.ERROR, locale.WARNING_AMOUNT_MINIMUM, 'error')
        } else {
          //const blessing = document.getElementById('txt_blessing').value
          // if (blessing === '') {
          //   swal('Valid Fail', 'You Can\'t empty Blessing!', 'error')
          // } else {
          //const currency = document.getElementById('ddl_currency').value
          const currency = 'thb'
          //this.state.allTicket[0].currency.toLowerCase()
          const goods = await this.prepareGoods()
          const delivery = {
            id: this.state.selectOrderDelivery.id,
            name: this.state.selectOrderDelivery.title,
            address: this.state.deliveryAddress,
            phone: this.state.tel
          }
          this.props.onSubmitAmountTicket({
            ...params,
            currency,
            //blessing,
            goods,
            delivery,
          })
          // }
        }
      }
    } else {
      // Prepare data to place qr payment
      if (params.package_id && params.type && params.event_id) {
        const amount = parseFloat(this.state.finalAmount)
        if (isNaN(amount)) {
          swal(locale.ERROR, locale.WARNING_AMOUNT_EMPTY, 'error')
        } else if (amount <= 0) {
          swal(locale.ERROR, locale.WARNING_AMOUNT_MINIMUM, 'error')
        } else {
          const currency = 'thb'
          //this.state.allTicket[0].currency.toLowerCase()
          const goods = await this.prepareGoods()
          const delivery = {
            id: this.state.selectOrderDelivery.id,
            name: this.state.selectOrderDelivery.title,
            address: this.state.deliveryAddress,
            phone: this.state.tel
          }
          const orderPayload = {
            order_type: params.type,
            package_id: parseInt(params.package_id),
            event_id: parseInt(params.event_id),
            currency: currency,
            goods: goods,
            delivery: delivery
          }
          this.setState({ orderPayload: orderPayload }, async () => {
            const createOrderResponse = await createOrderAPI(this.state.orderPayload)
            this.setState({ orderID: createOrderResponse.data.id })
            if (createOrderResponse.code === 'OK') {
              const chargeOrdeyPayload = {
                payment_method: paymentType,
                charge_parameters: {}
              }
              const chargeOrderResponse = await orderChargeAPI(createOrderResponse.data.id, chargeOrdeyPayload)
              //console.log(chargeOrderResponse)
              if (chargeOrderResponse.code === 'OK') {
                //console.log('Success')
                this.setState({
                  waitQrPayment: true,
                  qrImage: chargeOrderResponse.data.qr_code,
                  qrAmount: chargeOrderResponse.data.amount
                })
                this.checkPaymentStatus(createOrderResponse.data.id)
              } else {
                console.log('fail')
              }
            } else {
              console.log('fail')
            }
          })
        }
      }
    }
  }

  onJoinWithOutPayment = () => ModalHandler.openModal('modalConfirmJoinFree')
  onJoinWithOutPaymentSubmit = () => {
    const params = queryString.parse(this.props.location.search)
    if (params.package_id && params.type && params.event_id) {
      const amount = 0
      const blessing = ''
      const currency = document.getElementById('ddl_currency').value
      this.props.onSubmitAmountTicket({
        ...params,
        join_type: 'free',
        currency,
        blessing,
      })
    }
  }

  requestGoodsByEventIdhandeler = (eventId) => {
    this.props.onLoadGoodsDelivery(eventId, (resp, error) => {
      this.requestDeliveryByEventIdhandeler(resp)
      const allGoods = resp.goods.goods
      if (resp.goods.total_count > 0) {
        const allTicket = allGoods.filter(index => index.type === 'ticket')
        const allSouvenir = allGoods.filter(index => index.type === 'merchandise')
        let tempSouvenirImage = []
        allSouvenir.map((data, index) => {
          if (data.picture_en) {
            let tempjson = {}
            tempjson[`${data.id}`] = data.picture_en
            tempSouvenirImage.push(tempjson)
          }
        })
        let rearraySouvenirImage = []
        let temprearray = []
        let rearrayCount = 0
        tempSouvenirImage.map((data, index) => {
          temprearray.push(data)
          rearrayCount = rearrayCount + 1
          console.log(temprearray)
          if (rearrayCount == 4 || (tempSouvenirImage.length == (index + 1))) {
            rearraySouvenirImage.push(temprearray)
            rearrayCount = 0
            temprearray = []
          }
        })
        let rearraySouvenirImageMobile = []
        temprearray = []
        rearrayCount = 0
        tempSouvenirImage.map((data, index) => {
          temprearray.push(data)
          rearrayCount = rearrayCount + 1
          console.log(temprearray)
          if (rearrayCount == 2 || (tempSouvenirImage.length == (index + 1))) {
            rearraySouvenirImageMobile.push(temprearray)
            rearrayCount = 0
            temprearray = []
          }
        })
        this.setState({
          allTicket: allTicket,
          allSouvenir: allSouvenir,
          souvenirImage: rearraySouvenirImage,
          souvenirImageMobile: rearraySouvenirImageMobile
        },
          this.extractTicketAndGoodsHandler)
      } else {
        this.setState({ readyPageLogin: false })
      }
    })
  }

  extractTicketAndGoodsHandler = () => {
    let NumberOfTitle = 0
    let rearangeArray = []
    let tempArray = []
    this.state.allTicket.map((data, index) => {
      let tempNumOfTitle = parseInt(data.name_en.slice(6, 8))
      if (NumberOfTitle < tempNumOfTitle) {
        NumberOfTitle = tempNumOfTitle
      }
    })
    for (let i = 0; i < NumberOfTitle; i++) {
      let index = (i + 1).toString()
      if ((i + 1) < 9) {
        tempArray = this.state.allTicket.filter(data => data.name_en.includes(`Title0${index}`))
        rearangeArray = [...rearangeArray, tempArray]
      }
    }
    this.setState({ rearrangeTicket: rearangeArray })

    let NumberOfTitleSouvenir = 0
    let rearangeArray2 = []
    let tempArray2 = []
    this.state.allSouvenir.map((data, index) => {
      let tempNumOfTitleSouvenir = parseInt(data.name_en.slice(6, 8))
      if (NumberOfTitleSouvenir < tempNumOfTitleSouvenir) {
        NumberOfTitleSouvenir = tempNumOfTitleSouvenir
      }
    })
    for (let i = 0; i < NumberOfTitleSouvenir; i++) {
      let index = (i + 1).toString()
      if ((i + 1) < 9) {
        tempArray2 = this.state.allSouvenir.filter(data => data.name_en.includes(`Title0${index}`))
        rearangeArray2 = [...rearangeArray2, tempArray2]
      }
    }
    this.setState({ rearrangeSouvenir: rearangeArray2, readyPageLogin: true })

  }

  requestDeliveryByEventIdhandeler = (deliveryData) => {
    const allDelivery = deliveryData.delivery.delivery_options
    //console.log(error)
    if (deliveryData.delivery.total_count > 0) {
      this.setState({ allDelivery: [allDelivery] })
    }
  }

  selectTicketGoodsDeliveryhandeler = (type, inventoryTitle, inventoryID, quantity, price, currency, action) => {
    if (type === 'Ticket') {
      if (this.state.selectOrderTicket.id !== inventoryID) {
        this.setState({
          selectOrderTicket: {
            type: type,
            title: inventoryTitle,
            id: inventoryID,
            quantity: quantity,
            price: price,
            currency: currency
          },
          isOpenGoodsAndSouvenir: true,
          isOpenPaymentAndSubmit: true,
        })
      }
      return
    }
    if (type === 'Souvenir') {
      if (this.state.selectOrderSouvenir.length != 0) {
        let isChange = false
        this.state.selectOrderSouvenir.map((data, index) => {
          if (data.id == inventoryID) {
            if (action === 'plus') {
              data.quantity = data.quantity + 1
              console.log('Plus', data.quantity, inventoryID)
            } else {
              if (data.quantity > 1) {
                data.quantity = data.quantity - 1
              }
            }
            this.state.returnGoodsAndSouvenirQuantity[inventoryID] = data.quantity
            this.setState({
              selectOrderSouvenir: this.state.selectOrderSouvenir,
              returnGoodsAndSouvenirQuantity: this.state.returnGoodsAndSouvenirQuantity
            })
            isChange = true
          } else if ((this.state.selectOrderSouvenir.length - 1) == index && !isChange) {
            console.log('add new order')
            let userselectOrderSouvenir = {
              type: type,
              title: inventoryTitle,
              id: inventoryID,
              quantity: quantity,
              price: price,
              currency: currency
            }
            this.state.returnGoodsAndSouvenirQuantity[inventoryID] = quantity
            let tempselectOrderSouvenir = [...this.state.selectOrderSouvenir, userselectOrderSouvenir]
            this.setState({
              selectOrderSouvenir: tempselectOrderSouvenir,
              isOpenDelivery: true,
              returnGoodsAndSouvenirQuantity: this.state.returnGoodsAndSouvenirQuantity
            })
          }
        })
      } else {
        console.log('empty order')
        let userselectOrderSouvenir = {
          type: type,
          title: inventoryTitle,
          id: inventoryID,
          quantity: quantity,
          price: price,
          currency: currency
        }
        this.state.returnGoodsAndSouvenirQuantity[inventoryID] = quantity
        let tempselectOrderSouvenir = [...this.state.selectOrderSouvenir, userselectOrderSouvenir]
        this.setState({
          selectOrderSouvenir: tempselectOrderSouvenir,
          isOpenDelivery: true,
          returnGoodsAndSouvenirQuantity: this.state.returnGoodsAndSouvenirQuantity
        })
      }
      return
    }
    if (type === 'Delivery') {
      if (this.state.selectOrderDelivery.id !== inventoryID) {
        this.setState({
          selectOrderDelivery: {
            type: type,
            title: inventoryTitle,
            id: inventoryID,
            price: price,
            currency: currency,
            quantity: quantity,
          }
        })
      }
      if (parseInt(price) === 0) {
        this.setState({ isOpenDeliveryAddress: false })
      } else {
        this.setState({ isOpenDeliveryAddress: true })
      }
      return
    }
  }

  deliveryAndPaymentDetail = (type, text) => {
    if (type === 'address') {
      this.setState({ deliveryAddress: text })
      return
    }
    if (type === 'tel') {
      this.setState({ tel: text })
      return
    }
    if (type === 'payment') {
      this.setState({ paymentMethod: text, isOpenBuyTicket: true })
      return
    }
  }

  onPreview = async (fileImage, goodsID) => {
    if (!fileImage.url && !fileImage.preview) {
      fileImage.preview = await this.props.getBase64(fileImage.originFileObj)
    }

    this.setState({
      previewImage: fileImage.url || fileImage.preview,
      previewVisible: true,
    })
  }

  closePreview = () => this.setState({ previewVisible: false })

  toggleOrderSummary = () => {
    this.setState({ isOpenOrderSummary: !this.state.isOpenOrderSummary })
  }

  getAmount = (amount) => {
    this.setState({ finalAmount: amount })
  }

  configDeadline = () => {
    const deadline = Date.now() + 10 * 60000;
    return deadline
  }

  checkPaymentStatus = async (orderID) => {
    if (orderID != '') {
      let notPaid = true
      while (notPaid) {
        let orderResponse = await getOrderAPI(orderID)
        if (orderResponse.code === 'OK') {
          if (orderResponse.data.status === 'paid') {
            notPaid = false
            const params = queryString.parse(this.props.location.search)
            navigate(`/event-detail/?id=${params.event_id}`)
          } else { await this.sleep(3000) }
        } else { console.log('get order fail') }
      }
    }
  }

  sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  }

  onPaymentExpire = () => {
    window.location.reload()
  }

  prepareGoods = async () => {
    const tempGoods = []
    if (this.state.selectOrderSouvenir.length > 0) {
      this.state.selectOrderSouvenir.map((data, index) => {
        tempGoods.push({
          id: data.id,
          quantity: data.quantity
        })
      })
      if (this.state.selectOrderTicket.id) {
        tempGoods.push({
          id: this.state.selectOrderTicket.id,
          quantity: this.state.selectOrderTicket.quantity
        })
      }
    }
    return tempGoods
  }

  render() {
    if (!this.state.readyPageLogin) {
      return <Loading />
    }
    if (this.props.state.eventDetail.title === '') {
      return <Loading />
    }
    return (
      <Layout auth={this.props.auth.currentUser !== null}>
        <SEO keywords={[`invitree`]} />
        {!this.state.isOpenOrderSummary ? (
          <View
            title={this.props.state.eventDetail}
            titleOriginal={this.props.state.eventDetailOriginal}
            ticket={this.state.rearrangeTicket}
            goodsAndSouvenir={this.state.rearrangeSouvenir}
            delivery={this.state.allDelivery}
            isOpen={{
              GoodsAndSouvenir: this.state.isOpenGoodsAndSouvenir,
              Delivery: this.state.isOpenDelivery,
              PaymentAndSubmit: this.state.isOpenPaymentAndSubmit,
              DeliveryAddress: this.state.isOpenDeliveryAddress,
              BuyTicket: this.state.isOpenBuyTicket
            }}
            selectTicketGoodsDeliveryhandeler={this.selectTicketGoodsDeliveryhandeler}
            returnQuantity={this.state.returnGoodsAndSouvenirQuantity}
            deliveryAndPaymentDetail={this.deliveryAndPaymentDetail}
            souvenirImage={this.state.souvenirImage}
            souvenirImageMobile={this.state.souvenirImageMobile}
            toggleOrderSummary={this.toggleOrderSummary}
            onPreview={this.onPreview}
            locale={this.props.app.locale[this.props.app.lang]}
            lang={this.props.app.lang}
          />
        ) : (
            <OrderSummary
              clubRegister={this.props.clubRegister}
              invitreeRegister={this.props.auth.currentUser.attributes}
              paymentMethod={this.state.paymentMethod}
              delivery={{ address: this.state.deliveryAddress, tel: this.state.tel }}
              selectOrderTicket={[this.state.selectOrderTicket]}
              selectOrderSouvenir={this.state.selectOrderSouvenir}
              selectOrderDelivery={[this.state.selectOrderDelivery]}
              toggleOrderSummary={this.toggleOrderSummary}
              onSubmit={this.onSubmit}
              getAmount={this.getAmount}
              lang={this.props.app.lang}
              locale={this.props.app.locale[this.props.app.lang]}
              qrPaymentStatus={
                {
                  waitQrPayment: this.state.waitQrPayment,
                  qrImage: this.state.qrImage,
                  qrAmount: this.state.qrAmount
                }
              }
              configDeadline={this.configDeadline}
              onPaymentExpire={this.onPaymentExpire}
            />
          )}
        <Modal
          visible={this.state.previewVisible}
          title={(this.props.app.lang === 'EN') ? this.props.app.locale.EN['PREVIEW_IMG'] : this.props.app.locale.TH['PREVIEW_IMG']}
          footer={null}
          onCancel={this.closePreview}
          style={{ color: 'white' }}
        >
          <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
        </Modal>
        {/* Remove old view (Can delete after develop new payment complete) */}
        {/* <View
          locale={this.props.app.locale[this.props.app.lang]}
          title={this.props.state.buyTicketDetail.title}
          logo={this.props.state.buyTicketDetail.logo_img}
          joinType={this.state.join_type}
          onSubmit={this.onSubmit}
          onJoinWithOutPayment={this.onJoinWithOutPayment}
        />
        <ModalConfirmJoinFree
          data={this.props.state.buyTicketDetailOriginal}
          onJoinEvent={this.onJoinWithOutPaymentSubmit}
          locale={this.props.app.locale[this.props.app.lang]}
        /> */}
      </Layout>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    app: state.app,
    state: state.home,
    clubRegister: state.clubRegister,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      checkAuthorization: authActions.checkAuthorization,
      onSubmitAmountTicket: paymentActions.onSubmitAmountTicket,
      onLoad: homeActions.onLoadDetail,
      onChooseEventForFree: homeActions.onChooseEventForFree,
      onLoadGoodsDelivery: homeActions.onLoadGoodsDelivery,
      onSetProfile: clubRegisterActions.onSetProfile,
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BuyTicketAmount)
