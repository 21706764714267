import React from 'react'
import { Statistic } from 'antd';

const { Countdown } = Statistic;

function countdown(props) {
    return (
        <Countdown style={{ marginTop: '10px'}} title={props.locale['QR_EXPIRE']} value={props.deadline} onFinish={props.onPaymentExpire} />
    )
}

export default countdown
